import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import Faster5xCard from 'common/assets/image/syncStage/card-syncstage-5x-to-7x-faster.png';
import Devices50Card from 'common/assets/image/syncStage/card-50-devices.png';
import HighlyScalable from 'common/assets/image/syncStage/card-syncstage-highly-scalable.png';
import OnDeviceCard from 'common/assets/image/syncStage/card-on-device.png';
import Image from 'common/components/Image';
import Fade from 'react-reveal/Fade';
import SyncStageWrapper, { GridWrapper, styles } from './syncStage.style';

const SyncStageSection = () => {
  const { row, title, contentWrapper, textStyleHeader1 } = styles;

  return (
    <SyncStageWrapper>
      <Container width="1220px">
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading {...title} content="SyncStage" />
          </Box>
        </Box>
        <Box className="text" {...row}>
          <Box>
            <Text {...textStyleHeader1} content="Ultra Fast Audio Pipeline" />
          </Box>
        </Box>
        <Fade bottom delay={5}>
          <GridWrapper>
            <Image src={Faster5xCard} alt={'5x to 7x faster'} loading="lazy" />
            <Image src={OnDeviceCard} alt={'On device'} loading="lazy" />
            <Image src={Devices50Card} alt={'50+ devices'} loading="lazy" />
            <Image src={HighlyScalable} alt={'Highly Scalable'} loading="lazy" />
          </GridWrapper>
        </Fade>
      </Container>
    </SyncStageWrapper>
  );
};

export default SyncStageSection;
