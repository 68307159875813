import styled from 'styled-components';

const HeroSectionWrapper = styled.section`
  padding: 0;
  background-repeat: no-repeat;
  background-position: 0px 100px;

  .swiper {
    width: 100%;
    min-height: 100vh;
    height: 100%;
  }

  .swiper-pagination-bullet {
    background-color: white;
    height: 22px;
    width: 22px;
  }

  .section-idle {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    .gradient-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  img,
  video {
    border-radius: 35px;
    width: 100%;
    object-fit: cover;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    box-sizing: border-box;

    @media (max-width: 576px) {
      flex-direction: column;
    }

    a {
      width: fit-content;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }
`;

export default HeroSectionWrapper;

const titleStyles = {
  fontWeight: 'bold',
  color: '#fff',
  letterSpacing: '-0.025em',
  mb: ['20px', '25px', '25px', '25px', '25px'],
  lineHeight: '1',
  textAlign: 'left',
  fontStyle: 'normal',
};
export const styles = {
  firstSwipeTitle: {
    ...titleStyles,
    fontSize: ['40px', '45px', '50px', '60px'],
  },
  secondSwipeTitle: {
    ...titleStyles,
    fontSize: ['35px', '45px', '50px', '55px'],
    mb: ['20px'],
  },
};
