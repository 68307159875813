import React from 'react';
import PartnerSection from 'containers/SyncStage/Partner';
import PlatformSection from '../containers/SyncStage/Platform';
import OptimizedSection from '../containers/SyncStage/Optimized';
import HeroSection from '../containers/SyncStage/Hero';
import { handleWhitepaper5GFF } from '../common/handlers';
import SyncStageSection from '../containers/SyncStage/SyncStage';
import Layout from '../components/layout';

const SyncStage = () => {
  return (
    <Layout>
      <HeroSection handleWhitepaper5GFF={handleWhitepaper5GFF} />
      <SyncStageSection />
      <PlatformSection />
      <PartnerSection />
      <OptimizedSection />
    </Layout>
  );
};
export default SyncStage;
