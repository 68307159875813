import styled from 'styled-components';

const SyncStageSectionWrapper = styled.section`
  padding: 100px 0;

  @media (max-width: 1220px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
  }
`;

export default SyncStageSectionWrapper;

export const GridWrapper = styled.div`
  padding-top: 40px;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1220px;
  @media only screen and (max-width: 768px) {
    gap: 50px 30px;
  }
  @media only screen and (max-width: 480px) {
    gap: 50px 30px;
  }
`;

export const styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '50%'],
  },
  title: {
    fontSize: ['34px', '42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    textAlign: 'center',
  },
  textStyleHeader1: {
    color: '#fff',
    maxWidth: 980,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '25px',
  },
};
