import styled from 'styled-components';
import OptimizedBG from 'common/assets/image/bg-optimized.png';

const InfoSectionWrapper = styled.section`
  z-index: 0;
  padding-inline: 2rem;

  :before {
    content: '';
    background-image: url(${OptimizedBG});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  .email-us {
    font-size: 25px;
    color: white;
    margin-bottom: 40px;
    line-height: 1.3;

    span {
      color: #36dbfd;
      cursor: pointer;
    }
  }
  .two-col-grid {
    display: flex;
    justify-content: center;
  }

  .image-wrapper img {
    max-width: 90px;
    height: 100%;
  }
  @media (max-width: 575px) {
    padding: 20px;
  }
`;

export const styles = {
  title: {
    fontSize: ['26px', '34px', '42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    pt: ['60px', '80px', '100px', '150px', '150px'],
    pb: ['30px', '40px', '50px', '75px', '75px'],
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    pt: ['30px', '40px', '50px', '90px', '90px'],
    pb: ['60px', '80px', '100px', '150px', '150px'],
  },
  button: {
    width: '100%',
  },
};
export default InfoSectionWrapper;
