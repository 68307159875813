import React from 'react';
import Heading from 'common/components/Heading';
import Thumbnail5GFF from 'common/assets/image/syncStage/5gff-thumbnail.jpg';
import Thumbnail5GFFSmall from 'common/assets/image/syncStage/5gff-thumbnail-small.webp';
import LogoAnimatedSmall from 'common/assets/image/syncStage/syncstage-logo-animated-frame.webp';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import Video from '../../../common/assets/1.mp4';

import HeroSectionWrapper, { styles } from './hero.style';
import PlayableBanner from 'components/Banner/PlayableBanner/PlayableBanner';
import BannerSideText from 'components/Banner/BannerSideText/BannerSideText';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';
import OutlinedButton from 'common/components/Button/OutlinedButton/OutlinedButton';
import LazyMedia from 'common/components/LazyMedia/LazyMedia';

const HeroSection = ({ handleWhitepaper5GFF }) => {
  const { firstSwipeTitle, secondSwipeTitle } = styles;

  return (
    <HeroSectionWrapper>
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }}
        modules={[Pagination, Autoplay]}
      >
        <SwiperSlide>
          <PlayableBanner
            url={'https://www.youtube.com/watch?v=ghHb7hmMbWc'}
            idle={
              <div className="section-idle">
                <LazyMedia src={Video} loadingSrc={LogoAnimatedSmall} alt="SyncStage Ultra Fast Audio Pipeline" />
                <div className="gradient-layer"></div>
              </div>
            }
          >
            <BannerSideText>
              <Heading {...firstSwipeTitle}>
                <React.Fragment>
                  <span className="emphasize">SYNCSTAGE</span>
                  <br />
                  ULTRA FAST
                  <br />
                  AUDIO PIPELINE
                </React.Fragment>
              </Heading>
              <div className="buttons">
                <a href={process.env.REQUEST_DEMO_URL} target="_blank" rel="noreferrer">
                  <PrimaryButton title="SIGN UP" />
                </a>
                <a href="/developers">
                  <OutlinedButton title="LEARN MORE" />
                </a>
              </div>
            </BannerSideText>
          </PlayableBanner>
        </SwiperSlide>
        <SwiperSlide>
          <PlayableBanner
            url={'https://www.youtube.com/watch?v=Cm-S46mguC0'}
            idle={
              <div className="section-idle">
                <LazyMedia src={Thumbnail5GFF} loadingSrc={Thumbnail5GFFSmall} alt="5GFF" />
                <div className="gradient-layer"></div>
              </div>
            }
          >
            <BannerSideText>
              <Heading {...secondSwipeTitle}>
                <React.Fragment>
                  THE WORLD&apos;S
                  <br />
                  FIRST MUSIC
                  <br />
                  COLLABORATION
                  <br />
                  USING <span className="emphasize">SYNCSTAGE</span>
                </React.Fragment>
              </Heading>
              <div className="buttons">
                <PrimaryButton onClick={handleWhitepaper5GFF} title="Download the whitepaper" />
              </div>
            </BannerSideText>
          </PlayableBanner>
        </SwiperSlide>
      </Swiper>
    </HeroSectionWrapper>
  );
};

export default HeroSection;
