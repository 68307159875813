import styled from 'styled-components';
import BG from 'common/assets/image/syncStage/platform-bg.png';

const PartnerSectionWrapper = styled.section`
  padding: 150px 0;
  background-image: url(${BG});
  background-repeat: no-repeat;
  background-position: 0 0px;
  background-size: contain;

  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0;
    background-image: none;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
    background-image: none;
  }
`;

export default PartnerSectionWrapper;

export const GridWrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    gap: 50px 30px;
  }
  @media only screen and (max-width: 480px) {
    gap: 50px 30px;
  }
`;

export const styles = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: ['34px', '42px', '44px', '48px'],
    fontWeight: 'bold',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '2rem',
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  textStyle: {
    color: '#fff',
    maxWidth: 980,
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: ['20px', '25px'],
    lineHeight: '150%',
    mb: ['18px', '25px'],
  },
  textStyleHeader1: {
    color: '#fff',
    maxWidth: 980,
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: ['20px', '25px'],
    textTransform: 'uppercase !important',
    lineHeight: '150%',
    mt: '3rem',
  },
  textStyleHeader2: {
    color: '#36DBFD',
    maxWidth: 980,
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: ['25px', '30px'],
    lineHeight: '150%',
    mt: '5rem',
  },
};
