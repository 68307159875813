import React from 'react';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import OptimizedSectionWrapper, { styles } from './optimized.style';
import LiveImage from 'common/assets/image/syncStage/icon-live.png';
import GameImage from 'common/assets/image/syncStage/icon-game-handler.png';
import TDImage from 'common/assets/image/syncStage/icon-metaverse.png';
import { IconsGridRow } from '../../../common/components/IconsGrid';
import { handleContact } from 'common/handlers';
import PrimaryButton from 'common/components/Button/PrimaryButton/PrimaryButton';

const OptimizedSection = () => {
  const { title, buttonWrapper, button } = styles;
  return (
    <OptimizedSectionWrapper id="info_section">
      <Box>
        <Heading {...title} content="Optimized for your use case" />
      </Box>
      <Container fullWidth noGutter className="two-col-grid">
        <IconsGridRow
          items={[
            {
              link: '/music',
              icon: LiveImage,
              alt: 'Speed',
              title: 'MUSIC',
              description: 'Jam together when you are not together',
              borderOpacity: 1,
            },
            {
              link: '/gaming',
              icon: GameImage,
              alt: 'Security',
              title: 'GAMING',
              description: 'Win together when you are not together',
              borderOpacity: 1,
            },
            {
              link: '/metaverse',
              icon: TDImage,
              alt: 'TDImage',
              title: 'METAVERSE',
              description: 'Be together when you are not together',
              borderOpacity: 1,
            },
          ]}
        />
      </Container>
      <Box {...buttonWrapper}>
        <Box style={{ textAlign: 'center' }}>
          <p className="email-us">
            HAVE ANOTHER USE CASE IN MIND FOR SYNCSTAGE?{' '}
            <span>
              <span onClick={handleContact}>EMAIL US</span>
            </span>
          </p>
          <a href={process.env.REQUEST_DEMO_URL} target="_blank" rel="noreferrer">
            <PrimaryButton {...button} title="Sign Up" />
          </a>
        </Box>
      </Box>
    </OptimizedSectionWrapper>
  );
};

export default OptimizedSection;
