import React from 'react';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';

import PlatformSectionWrapper, { styles } from './platform.style';

const PlatformSection = () => {
  const { row, title, textStyle, textStyleHeader1, textStyleHeader2 } = styles;
  return (
    <PlatformSectionWrapper>
      <Container>
        <Box {...row}>
          <Heading {...title} content="Platform" />
        </Box>
        <Box className="text" {...row}>
          <Box>
            <Text
              {...textStyle}
              content="SyncStage allows application developers to create synchronous low audio latency experiences within their applications to enable their users to collaborate with others in ‘real time’."
            />
            <Text
              {...textStyle}
              content="Developers install our patented audio pipeline via our iOS, Android and/or Web SDK for Windows and MacOS into their applications and we power the end-to-end communications & collaborations of their users."
            />
            <Text
              {...textStyleHeader1}
              content="SING, CELEBRATE. JAM, LEARN. LAUGH. MOURN. WIN. TEACH. LISTEN. PRAY. AND PLAY TOGETHER. THE POSSIBILITIES ARE ENDLESS."
            />
            <Text {...textStyleHeader2} content="WHAT WILL YOU CREATE TOGETHER?" />
          </Box>
        </Box>
      </Container>
    </PlatformSectionWrapper>
  );
};

export default PlatformSection;
